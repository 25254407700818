.status-approved {
    border-color: #19bb4f !important;
    color: #19bb4f !important;
    background-color: transparent !important;
}
.status-rejected {
    border-color: #faad14 !important;
    color: #faad14 !important;
    background-color: transparent !important;
}
